<template>
  <div class="home">
    <SliderHome :parntsliderData="parntsliderData" />
    <WhoWe :parntabout="parntabout" />
    <Services
      :parntservices="parntservices"
      :servicefeatures="servicefeatures"
    />
    <TrainingSolutions
      :parntsolutions="parntsolutions"
      :parntsolutionsfeature="parntsolutionsfeature"
      :logos="logos"
    />
  </div>
</template>

<script>
import SliderHome from "../../components/Global/home/SliderHome.vue";
import WhoWe from "../../components/Global/home/WhoWe.vue";
import Services from "../../components/Global/home/ServesesHome.vue";
import TrainingSolutions from "../../components/Global/home/TrainingSolutions.vue";
import axios from "axios";

export default {
  name: "HomeView",
  components: {
    SliderHome,
    WhoWe,
    Services,
    TrainingSolutions,
  },
  data() {
    return {
      parntsliderData: [],
      parntabout: [],
      parntservices: [],
      parntsolutions: [],
      parntsolutionsfeature: [],
      servicefeatures: [],
      logos: [],
    };
  },
  async mounted() {
    try {
      const response = await axios.get(
        "https://back.success.sa/public/api/fetch-home"
      );
      if (response.data.status == true) {
        this.parntsliderData = response.data.data.header;
        this.parntabout = response.data.data.about_us;
        this.parntservices = response.data.data.service;
        this.parntsolutions = response.data.data.solutions;
        this.parntsolutionsfeature = response.data.data.solutions_feature;
        this.servicefeatures = response.data.data.service_features;
        this.logos = response.data.data.logos;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Optionally, handle the error (e.g., show an error message)
    }
  },
};
</script>
