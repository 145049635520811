<template>
  <div class="who-we">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
        <div class="adress">
          <!-- <h4>
            <img src="@/assets/photos/Group.svg" alt="" />{{
              parntabout.header
            }}
          </h4> -->
          <p>{{ parntabout.description }}</p>
          <!-- <button class="more" @click="$router.push('/about')">
            عرض المزيد <i class="fa-solid fa-circle-left"></i>
          </button> -->
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
        <div class="img" v-for="(img, i) in parntabout.media" :key="i">
          <img :src="img" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "WhoWe",
  data() {
    return {};
  },
  props: {
    parntabout: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.who-we {
  @media screen and (max-width: 600px) {
    margin-top: 20rem;
  }
}
</style>
