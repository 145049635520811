<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <router-link class="navbar-brand" :to="`/${langauge}`">
        <img :src="parentSitting.Logo_link" alt="Logo" />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link
              :to="`/${langauge}`"
              class="nav-link"
              aria-current="page"
              @click="closeNavbar"
            >
              <span v-if="$route.params.lang == 'ar'">الرئيسية</span>
              <span v-else>Home</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="`/${langauge}/about`"
              class="nav-link"
              @click="closeNavbar"
            >
              <span v-if="$route.params.lang == 'ar'">من نحن</span>
              <span v-else>About</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="`/${langauge}/contact-us`"
              class="nav-link"
              @click="closeNavbar"
            >
              <span v-if="$route.params.lang == 'ar'">تواصل معنا</span>
              <span v-else>Contact Us</span>
            </router-link>
          </li>
        </ul>
        <div class="login">
          <router-link
            :to="`/${langauge}/contact-us`"
            class="nav-link"
            @click="closeNavbar"
          >
            <span v-if="$route.params.lang == 'ar'">اتصل بنا</span>
            <span v-else>Contact Us</span>
          </router-link>
        </div>
        <div class="imageLang mx-3">
          <button
            @click="changeLangRoute('ar')"
            class="btn d-flex outline-0 border-0 align-items-center justify-content-center"
            v-if="$route.params.lang == 'en'"
          >
            <img src="@/assets/photos/flag.png" class="w-75" alt="" />
            <span class="mx-1">AR</span>
          </button>
          <button
            @click="changeLangRoute('en')"
            class="btn d-flex outline-0 border-0 align-items-center justify-content-center"
            v-else
          >
            <img src="@/assets/photos/united-states.png" class="w-75" alt="" />
            <span class="mx-1">EN</span>
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js";
import axios from "axios";
export default {
  data() {
    return {
      parentSitting: [],
      langauge: "ar",
    };
  },
  methods: {
    changeLangRoute(lang) {
      this.$router.push({ params: { lang } });
      this.langauge = lang;
      localStorage.setItem("lang-success", lang);
      // console.log(lang);
    },
    closeNavbar() {
      // Get the Bootstrap collapse instance and hide it
      const collapseElement = document.getElementById("navbarSupportedContent");
      const collapse = new bootstrap.Collapse(collapseElement, {
        toggle: false,
      });
      collapse.hide();
    },
  },
  async mounted() {
    try {
      const response = await axios.get(
        "https://back.success.sa/public/api/fetch-contact_us"
      );
      if (response.data.status == true) {
        this.parentSitting = response.data.data.settings;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
};
</script>

<style scoped>
/* Scoped styles for this component */
.navbar-brand img {
  max-height: 50px; /* Adjust as needed */
}
</style>
