<template>
  <div class="home">
    <!-- {{ Home.data.header }} -->
    <SliderHome :parntsliderData="parntsliderData" />
    <WhoWe :parntabout="parntabout" />
    <Services
      :parntservices="parntservices"
      :servicefeatures="servicefeatures"
    />
    <TrainingSolutions
      :parntsolutions="parntsolutions"
      :parntsolutionsfeature="parntsolutionsfeature"
      :logos="logos"
    />
  </div>
</template>

<script>
import SliderHome from "@/components/Global/home/SliderHome.vue";
import WhoWe from "@/components/Global/home/WhoWe.vue";
import Services from "@/components/Global/home/ServesesHome.vue";
import TrainingSolutions from "@/components/Global/home/TrainingSolutions.vue";
// import axios from "axios";
import Home from "@/data/home.json";

export default {
  name: "HomeView",
  components: {
    SliderHome,
    WhoWe,
    Services,
    TrainingSolutions,
  },
  data() {
    return {
      parntsliderData: [],
      parntabout: [],
      parntservices: [],
      parntsolutions: [],
      parntsolutionsfeature: [],
      servicefeatures: [],
      logos: [],
      Home,
    };
  },
  async mounted() {
    this.parntsliderData = this.Home.data.header;
    this.parntabout = this.Home.data.about_us;
    this.parntservices = this.Home.data.service;
    this.parntsolutions = this.Home.data.solutions;
    this.parntsolutionsfeature = this.Home.data.solutions_feature;
    this.servicefeatures = this.Home.data.service_features;
    this.logos = this.Home.data.logos;
  },
};
</script>
